<template>
    <v-row justify=center class="mt-4">
        <v-col cols=9>
            <confirmation-recall-header step=1 title="Demande de rappel" />
            <confirmation-recall />
        </v-col>
    </v-row>
</template>

<script>

import ConfirmationRecallHeader from '@/components/layout/HeaderBase.vue';
import ConfirmationRecall from '@/components/confirmation-recall/ConfirmationRecall.vue';

export default {

    components: {
        ConfirmationRecallHeader,
        ConfirmationRecall,
    },

};

</script>