import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import Login from '@/views/LoginPage.vue';
import ChildCreation from '@/views/ChildCreationPage.vue';
import ConfirmationInscription from '@/views/ConfirmationInscriptionPage.vue';
import ConfirmationRecall from '@/views/ConfirmationRecallPage.vue';
import UserCreation from '@/views/UserCreationPage.vue';
import MaintenanceComponent from '@/views/MaintenancePage.vue';
import ModuleInscription from '@/views/ModuleInscriptionPage.vue';
import Summary from '@/views/SummaryPage.vue';


const authGuard = (to, from, next) => {
  const isAuth = store.getters['auth/isAuth'];
  const protectedRoutesWhenAuth = ['/login', '/user-creation', '/child-creation'];

  if (isAuth && protectedRoutesWhenAuth.includes(to.path)) {
    next('/module-inscription');
  } else {
    next();
  }
};

const maintenanceGuard = (to, from, next) => {
  const maintenanceMode = false;

  if (to.path === '/maintenance') {
    next();
  } else if (maintenanceMode) {
    next('/maintenance');
  } else {
    next();
  }
};

const childCreationGuard = (to, from, next) => {
  const parent = store.getters['payload/getParent'];

  if (parent && Object.keys(parent).length > 0) {
    next();
  } else {
    next('/user-creation');
  }
};

const moduleInscriptionGuard = (to, from, next) => {
  const parent = store.getters['payload/getParent'];
  const students = store.getters['payload/getStudents'];

  if (parent && Object.keys(parent).length > 0 && students.length > 0) {
    next();
  } else {
    next('/user-creation');
  }
};

const routes = [
    {
      path: '/',
      redirect: '/user-creation',
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: authGuard,
    },
    {
      path: '/user-creation',
      name: 'UserCreation',
      component: UserCreation,
      beforeEnter: authGuard,
    },
    {
      path: '/child-creation',
      name: 'ChildCreation',
      component: ChildCreation,
      beforeEnter: (to, from, next) => {
          authGuard(to, from, () => {
              childCreationGuard(to, from, next);
          });
      },
    },
    {
      path: '/module-inscription',
      name: 'ModuleInscription',
      component: ModuleInscription,
      beforeEnter: moduleInscriptionGuard,

    },
    {
      path: '/summary',
      name: 'Summary',
      component: Summary,
      beforeEnter: moduleInscriptionGuard,
    },
    {
      path: '/confirmation-inscription',
      name: 'ConfirmationInscription',
      component: ConfirmationInscription,

    },
    {
      path: '/confirmation-recall',
      name: 'ConfirmationRecall',
      component: ConfirmationRecall,
    },
    {
      path: '/maintenance',
      name: 'MaintenanceComponent',
      component: MaintenanceComponent
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(maintenanceGuard);

export default router
