<template>
    <v-form ref="userForm">
        <v-row>
            <v-col cols="12" sm="4" xl="2" class="py-2">
                <select-field 
                    v-model="user.gender" 
                    label="Civilité" 
                    :rules="genderRules"
                    :items="genderOptions" 
                    item-title="text"
                    item-value="value"
                ></select-field>
            </v-col>
            <v-col cols="12" sm="8" xl="5" class="py-2">
                <text-field v-model="user.firstname" label="Prénom" :rules="firstNameRules"></text-field>
            </v-col>
            <v-col cols="12" xl="5" class="py-2">
                <text-field v-model="user.lastname" label="Nom" :rules="lastNameRules"></text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="py-2">
                <text-field-phone 
                    v-model="phoneInput" 
                    label="Téléphone" 
                    :rules="phoneRules"
                    @input="handlePhoneInput"
                ></text-field-phone>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="py-2">
                <text-field v-model="user.email" label="Email" :rules="emailRules"></text-field>
            </v-col>
        </v-row>
        <div class="text-start text-primary font-weight-bold text-h6 mt-3 mb-5">Comment avez-vous connu Cours Thalès ?</div>
        <radio-button v-model="user.medium" :constants="mediums" :rules="mediumRules"></radio-button>
        <v-row class="mt-3">
            <v-col cols="12" class=" text-start">
                <v-btn @click="validateUserForm" color="primary" class="font-weight-bold">
                    V<span class="text-lowercase">alider</span>
                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>

import { mapGetters } from 'vuex';

import RadioButton from '@/components/user-creation/UserCreationFormRadioButton.vue'
import SelectField from '@/components/form-base/SelectFieldBase.vue';
import TextField from '@/components/form-base/TextFieldBase.vue';
import TextFieldPhone from '@/components/form-base/TextFieldPhoneBase.vue';

export default {
    
    components: {
        RadioButton,
        SelectField,
        TextField,
        TextFieldPhone
    },

    data() {
        return {
            phoneInput: '',
            user: {
                gender: null,
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                mobilePhone: '',
                medium: '',
            },
            mediums: [],
            genderOptions: [
                { text: 'M.', value: '1' },
                { text: 'Mme', value: '2' }
            ],
            genderRules: [ 
                v => !!v || "La civilité est requise",
            ],
            classRules: [ 
                v => !!v || "La classe est requise",
            ],
            mediumRules: [ 
                v => !!v || "Ce champs est requis",
            ],
            phoneRules: [
                v => !!v || "Le numéro de téléphone est requis",
            ],
            emailRules: [ 
                v => !!v || "L'email est requis",
                v => /.+@.+\..+/.test(v) || 'Format invalide'
            ],
            firstNameRules: [ 
                v => !!v || "Le prénom est requis",
            ],
            lastNameRules: [ 
                v => !!v || "Le nom est requis",
            ],
        };
    },

    computed: {
        ...mapGetters('payload', ['getParent']),
    },

    methods: {

        handlePhoneInput(event) {
            const value = event?.target?.value;
            
            this.user.phone = '';
            this.user.mobilePhone = '';

            if (value == '') {
                this.phoneInput = '';
                return;
            }

            const cleanNumber = value.replace(/[^0-9]/g, '');
            
            if (cleanNumber.startsWith('06') || cleanNumber.startsWith('07')) {
                this.user.mobilePhone = cleanNumber;
            } else {
                this.user.phone = cleanNumber;
            }
            
            this.phoneInput = value;
        },
        
        getMediums() {
            this.$store.dispatch('getConstants')
            .then(response => {
                this.mediums = response;
            })
            .catch(error => console.log(error));
        },

        initializeFormData() {
            if (this.getParent !== null) {
                this.user = this.getParent
                if (this.getParent.mobilePhone) {
                    this.phoneInput = this.getParent.mobilePhone;
                } else if (this.getParent.phone) {
                    this.phoneInput = this.getParent.phone;
                }
            }
        },

        async validateUserForm () {
            const { valid } = await this.$refs.userForm.validate()
            
            if(valid) {
                try {
                    await this.$store.dispatch('payload/updateParent', this.user);

                    const payload = {
                        email: this.user.email, 
                            payload: this.$store.getters['payload/getPayload']
                    };

                    await this.$store.dispatch('lead/createLead', payload);
                    
                    this.$router.push({ name: 'ChildCreation' });

                } catch (error) {
                    if (error.response && error.response.status === 409 && 
                        error.response.data.message === 'User already exists') {
                            this.$router.push({
                                name: 'Login',
                                query: { existingEmail: true }
                            });
                        }
                    console.error('Erreur lors de la création du lead :', error);
                }
        
            }
        },
    },

    created() {
        this.initializeFormData();
        this.getMediums();
    },
    
};

</script>