import axios from 'axios'

const state = {
    isAuth: localStorage.getItem('token') ? true : false,
};

const getters = {
    isAuth: state => state.isAuth
};

const actions = {

    login: ({ dispatch }, params) => {
        return axios.post(process.env.VUE_APP_INTRANET_API_URL + "form/auth/login", params, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(async (response) => {
            let token = response.data.token
            localStorage.setItem('token', token)

            await dispatch('payload/clearPayload')
    
            if (response.data.lead.parent) {
                await dispatch('payload/updateParent', response.data.lead.parent)
            }
    
            if (response.data.lead.students) {
                for (const student of response.data.lead.students) {
                    await dispatch('payload/addStudent', student)
                }
            }
    
            return token;
        })
        .catch((error) => {
            throw error;
        });
    },

    logout({ commit }) {
        commit('logout');
    },

}

const mutations = {

    logout(state) {
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token');
            localStorage.clear();
            state.selectedChild = null;
            state.user = null;
            state.isAuth = false;
        }
    },

};

export default {
	state,
	getters,
	actions,
	mutations
};