<template>
    <v-row class="px-3 mt-6 flex-column align-start">
        <v-btn 
            class="font-weight-normal d-block mb-2 text-decoration-none" 
            color="primary"
        >
            <a 
                href="https://app.hubspot.com/meetings/marc-serban?uuid=cb0b4d09-b738-4a9f-ad49-37419f3bf360" 
                target="_blank" 
                class="text-white text-decoration-none"
            >
                M<span class="text-lowercase">e faire rappeler par un conseiller</span>
            </a>
        </v-btn>
        <router-link to="summary">
            <v-btn class="font-weight-normal" color="primary">
                S<span class="text-lowercase">uivant</span>
                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
            </v-btn>
        </router-link>
    </v-row>
</template>