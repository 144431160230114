<template>
    <v-card class="rounded-xl mt-0 border-primary-card" :class="$vuetify.display.smAndUp ? 'px-7 py-4' : 'pa-3'">
        <div v-for="(student, studentIndex) in getStudents" :key="studentIndex">
            <summary-module-card 
                v-for="(session, preparationId) in student.preparations" 
                :key="preparationId"
                class="my-3"
                :session="session"
                :sessionPrice="getItemPrice(session, student.preparations)"
                :studentIndex="studentIndex"
                :childName="`${student.firstname} ${student.lastname}`"
            />
        </div>
        <v-card-title class="mb-2">
            <div class="d-flex align-center">
                <div class="font-weight-bold title">
                    Total : {{ totalPrice }} €
                </div>
            </div>
        </v-card-title>
    </v-card>
</template>

<script>

import { mapGetters } from 'vuex';

import moment from 'moment';

import SummaryModuleCard from '@/components/summary/SummaryModuleCard.vue';

export default {

    components: {
        SummaryModuleCard
    },

    computed: {

        ...mapGetters('payload', ['getPayload', 'getStudents']),


        totalPrice() {
            let price = 0;
            Object.values(this.getStudents).forEach(student => {
                if(student.preparations) {
                    Object.values(student.preparations).forEach(session => {
                        price += parseInt(this.getItemPrice(session, student.preparations));
                    });
                }
            });
            return price;
        }
        
    },

    methods: {

        getItemPrice(session, studentSessions) {
            const getCurrentPrice = {};
            session.prices.forEach((priceItem) => {
                const startDate = moment(priceItem.start_date).startOf('day');
                const endDate = moment(priceItem.end_date).startOf('day');
                const currentDate = moment().startOf('day');
                const checkDate = currentDate.isBetween(startDate, endDate, null, '[]');

                if (priceItem.end_date && checkDate) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                } else if (!priceItem.end_date && (startDate <= currentDate)) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                }
            });

            if ('AUTO1' in getCurrentPrice) {
                return getCurrentPrice['AUTO1'];
            }

            const numberItems = Object.values(studentSessions).filter(element => {
                if (element.product_type_id !== 2900012 &&
                    element.start_date === session.start_date &&
                    element.location && session.location &&
                    element.location.address === session.location.address) 
                {
                    return element;
                }
            }).length;

            if (numberItems < 2) {
                return '1MAT' in getCurrentPrice ? getCurrentPrice['1MAT'] : 0;
            }
            else if (numberItems === 2) {
                return '2MAT' in getCurrentPrice ? getCurrentPrice['2MAT'] : 0;
            }
            else if (numberItems > 2) {
                if ('3MAT' in getCurrentPrice) {
                    return getCurrentPrice['3MAT'];
                }
                else if ('2MAT' in getCurrentPrice) {
                    return getCurrentPrice['2MAT'];
                } 
                else {
                    return 0;
                }
            }

            return 0;
        },

    },

}

</script>


<style scoped>

.student {
    font-size: 20px;
}

.border-primary-card {
  border: 1px solid #272954;
}

</style>