<template>
    <v-row class="px-3 flex-column mb-5">
        <router-link @click="handleSubmitLead()" to="/confirmation-inscription" class="text-decoration-none mb-3">
            <v-btn color="secondary" class="font-weight-bold d-none d-sm-block">
                C<span class="text-lowercase">onfirmer cette inscription</span>
                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
            </v-btn>
            <v-btn color="secondary" class="font-weight-bold d-sm-none w-100">
                C<span class="text-lowercase">onfirmer cette inscription</span>
                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
            </v-btn>
        </router-link>
        <router-link @click="handleSubmitLead()" to="/confirmation-recall" class="text-decoration-none mb-3">
            <v-btn color="secondary" class="font-weight-bold d-none d-sm-block">
                É<span class="text-lowercase">tre rappelé</span>
                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
            </v-btn>
            <v-btn color="secondary" class="font-weight-bold d-sm-none w-100">
                É<span class="text-lowercase">tre rappelé</span>
                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
            </v-btn>
        </router-link>
        <router-link to="/module-inscription" class="text-decoration-none">
            <v-btn color="primary" class="font-weight-bold d-none d-sm-block">
                A<span class="text-lowercase">jouter une formation</span>
                <v-icon icon="mdi-plus" class="ml-1"></v-icon>
            </v-btn>
            <v-btn color="primary" class="font-weight-bold d-sm-none w-100">
                A<span class="text-lowercase">jouter une formation</span>
                <v-icon icon="mdi-plus" class="ml-1"></v-icon>
            </v-btn>
        </router-link>  
    </v-row>
</template>

<script>

import { mapGetters } from 'vuex';

export default {

    computed : {

        ...mapGetters('payload', ['getParent', 'getStudents']),

    },

    methods: {

        async handleSubmitLead() {
            try {

                const payload = {
                    email: this.getParent.email,
                    payload: this.$store.getters['payload/getPayload']
                };

                await this.$store.dispatch('lead/submitLead', payload);

                await this.$store.dispatch('payload/clearPayload');
                await this.$store.dispatch('lead/clearApiToken');
                
            } catch (error) {
                console.error('Erreur lors de la soumission du lead:', error);
            }
        }

    }
}
</script>