
const loadInitialState = () => {
    const savedPayload = localStorage.getItem('appPayload');
    if (savedPayload) {
        return {
            payload: JSON.parse(savedPayload)
        };
    }
    return {
        payload: {  
            students: [],
            parent: {},
        }
    };
};

const state = loadInitialState();

const getters = {
    getPayload: state => state.payload,
    getParent: state => state.payload.parent,
    getStudents: state => state.payload.students,
};

const actions = {

    updateParent({ commit }, parentData) {
        commit('set_parent', parentData);
        commit('save_to_localstorage');
    },

    addStudent({ commit }, studentData) {
        commit('add_student', studentData);
        commit('save_to_localstorage');
    },

    updateStudent({ commit }, { index, data }) {
        commit('update_student', { index, data });
        commit('save_to_localstorage');
    },

    removeStudent({ commit }, studentIndex) {
        commit('remove_student', studentIndex);
        commit('save_to_localstorage');
    },

    addStudentPreparation({ commit, state }, { studentIndex, updatedPreparations }) {
        const student = state.payload.students[studentIndex];
        if (student) {
          commit('update_student_preparations', { studentIndex, updatedPreparations });
          commit('save_to_localstorage');
        }
    },

    removeStudentPreparation({ commit }, { studentIndex, preparationId }) {
        commit('remove_student_preparation', { studentIndex, preparationId });
        commit('save_to_localstorage');
    },

    clearPayload({ commit }) {
        commit('clear_payload');
    },

};

const mutations = {

    set_parent(state, parentData) {
        state.payload.parent = { ...state.payload.parent, ...parentData };
    },

    add_student(state, studentData) {
        state.payload.students.push(studentData);
    },

    update_student(state, { index, data }) {
        if (index >= 0) {
            state.payload.students[index] = { ...state.payload.students[index], ...data };
        }
    },

    remove_student(state, studentIndex) {
        if (studentIndex >= 0 && studentIndex < state.payload.students.length) {
            state.payload.students.splice(studentIndex, 1);
        }
    },

    update_student_preparations(state, { studentIndex, updatedPreparations }) {
        if (studentIndex >= 0 && studentIndex < state.payload.students.length) {
          state.payload.students[studentIndex] = {
            ...state.payload.students[studentIndex],
            preparations: updatedPreparations
          };
        }
    },

    remove_student_preparation(state, { studentIndex, preparationId }) {
        if (studentIndex >= 0 && studentIndex < state.payload.students.length) {
            const student = state.payload.students[studentIndex];
            if (student && student.preparations && student.preparations[preparationId]) {
                const updatedPreparations = { ...student.preparations };
                delete updatedPreparations[preparationId];
                
                state.payload.students[studentIndex] = {
                    ...student,
                    preparations: updatedPreparations
                };
            }
        }
    },

    clear_payload(state) {
        state.payload = { students: [], parent: {} };
        localStorage.removeItem('appPayload');
    },

    save_to_localstorage(state) {
        localStorage.setItem('appPayload', JSON.stringify(state.payload));
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};