import { createStore } from 'vuex'
import datalayer from './modules/app/datalayer'
import info from './modules/app/info'
import payload from './modules/app/payload'
import auth from './modules/api/auth'
import lead from './modules/api/lead'
import action from './modules/ammon/action'
import classe from './modules/ammon/class'
import constant from './modules/ammon/constant'
import formation from './modules/ammon/formation'
import product from './modules/ammon/product'
import subject from './modules/ammon/subject'

const store = createStore({
  modules: {
    auth,
    action,
    classe,
    constant,
    datalayer,
    formation,
    info,
    lead,
    payload,
    product,
    subject,
  }
})

export default store