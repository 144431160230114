import axios from 'axios'

const state = {
    token: localStorage.getItem('api_token') || null,
};

const getters = {

};

const actions = {

    createLead: async ({ commit }, datas) => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_INTRANET_API_URL + "lead", datas,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                }
            )
            .then((response) => {
                commit('setApiToken', response.data.token);            
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    },

    updateLead: async ({ state }, datas) => {
        return new Promise((resolve, reject) => {
            if (!state.token) {
                reject(new Error('No api token found'));
                return;
            }
    
            axios.put(process.env.VUE_APP_INTRANET_API_URL + "lead", datas,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${state.token}`
                    }
                }
            )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    },

    submitLead: async ({ state }, datas) => {
        return new Promise((resolve, reject) => {
            if (!state.token) {
                reject(new Error('No api token found'));
                return;
            }
    
            axios.post(process.env.VUE_APP_INTRANET_API_URL + "lead/submit", datas,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${state.token}`
                    }
                }
            )
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    },

    clearApiToken({ commit }) {
        commit('clearApiToken');
    }

}

const mutations = {

    setApiToken(state, token) {
        state.token = token;
        localStorage.setItem('api_token', token);
    },

    clearApiToken(state) {
        state.token = null;
        localStorage.removeItem('api_token');
    }

};

export default {
    namespaced: true,
	state,
	getters,
	actions,
	mutations
};